
import { Vue, Component, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import auth from '../store/modules/auth'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import Auth0AppState from '@/models/dto/Auth0AppState'
import { getTokenProvider, TokenProvider } from '@/services/authTokenRepository'
@Component({
  name: 'Auth0Callback',
  components: {
    CharterUPLogo,
  },
})
export default class Auth0Callback extends Vue {
  authHandled = false
  errorMessage: string = ''
  isAuth0Enabled: boolean = false
  authAttemptCount: number = 0

  // TODO: remove this mounted function once Auth0 is enabled
  async mounted(): Promise<void> {
    this.isAuth0Enabled = await this.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
    // this is a workaround for handling users coming back from Auth0 login using the Auth0 override link
    if (!this.isAuth0Enabled && !this.$auth0.isInitialized) {
      await this.$auth0.init()
    }
  }

  @Watch('$auth0.loading', { immediate: true })
  onAuth0LoadingChanged(loading: boolean) {
    if (loading) {
      return
    }
    if (!this.authHandled) {
      this.handleRedirectCallback()
    }
  }

  async handleRedirectCallback() {
    this.errorMessage = ''
    this.authHandled = true // Ensure we don't handle the callback more than once
    try {
      const appState = await this.resolveAppState()
      await this.resolveToken()
      this.goToRedirect(appState)
    } catch (error: any) {
      const currentTokenProvider = getTokenProvider()
      if (currentTokenProvider === TokenProvider.Auth0) {
        // This could be an accidental back button press, redirect to the home page.
        // If the user's token is not valid or expired, they will be redirected to the login page by our route guards.
        this.goToRedirect(null)
        return
      }

      const message = error?.response?.data?.message || error?.message || 'An error occurred while logging in. Please try again.'
      this.errorMessage = message
      console.error('Error handling redirect:', message)
    }
  }

  async resolveAppState(): Promise<Auth0AppState> {
    const appState = await this.$auth0.handleRedirectCallback()
    if (appState === undefined) {
      // User was not redirected from Auth0 login
      throw new Error('An error occured. Please try again.')
    }
    return appState
  }

  async resolveToken(): Promise<void> {
    const accessToken = await this.$auth0.getTokenSilently()
    await auth.auth0Login(accessToken)
  }

  goToRedirect(appState: Auth0AppState): void {
    const { redirectFrom, routeName } = appState || {
      redirectFrom: null,
      routeName: 'home',
    }
    this.$router.push((redirectFrom as RawLocation) || { name: routeName })
  }

  async logout() {
    await auth.logout()
  }

}
